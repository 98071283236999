<script>
import Dropdown from '../inputs/select.vue'

export default {
  name: 'plan_dropdown',
  extends: Dropdown,
  props: {
    options: {
      type: Array,
      default () {
        return [
          { label: 'Upkeep Cleaning', value: 'basic' },
          { label: 'Standard Cleaning', value: 'deep' }
        ]
      }
    },
    label: {
      type: String,
      default: 'Plan'
    },
    name: {
      type: String,
      default: 'Plan'
    }
  }
}
</script>
