import api from '@/clients/api'
import PickDateModal from '@/properties/shared/pick_date_modal.vue'
import AskIcalUrlModal from '@/modals/ask_ical_url_modal.vue'
export default {
  components: {
    PickDateModal,
    AskIcalUrlModal
  },
  data() {
    return {
      importPropertyId: null,
      savedIcalUrl: null,
      savedCheckInHour: null,
      savedCheckOutHour: null,
      showPickDateModalRaw: false,
      showFlexible: false,
      showIcalUrlModal: false,
      flexibleDescription: '',
      starts: [],
      uids: [],
      unreserved_ranges: [],
      pickedDates: [],
      pickedRanges: []
    }
  },
  computed: {
    showPickDateModal() {
      const show = ( ( (this.starts.length > 0) || (this.unreserved_ranges.length > 0) ) && this.showPickDateModalRaw)
      if (show) {
        return true
      }
    }
  },
  methods: {
    onPickDateModalClosed() {
      this.starts = []
      this.unreserved_ranges = []
      this.showPickDateModalRaw = false
      this.showFlexible = false
      this.pickedDates = []
      this.pickedRanges = []
    },
    onRequestBookings(startsWithIdx) {
      var starts = []

      for (var i = 0; i < startsWithIdx.length; i++) {
        starts.push({ start: startsWithIdx[i].start, plan: startsWithIdx[i].plan, uid: this.uids[startsWithIdx[i].i] })
      }
      api.mass_book(this.importPropertyId, starts).then(response => {
        this.importPropertyId = null
        this.showPickDateModalRaw = false
      }, (errors) => {
        this.showPickDateModalRaw = false
        this.$flasher.errors(errors.response.data.errors)
      })
    },
    onAskIcalUrl(propertyId) {
      this.importPropertyId = propertyId
      api.getPropertyIcalConfig(propertyId).then(response => {
        this.savedIcalUrl = response.data.ical_url
        this.savedCheckInHour = response.data.check_in_hour
        this.savedCheckOutHour = response.data.check_out_hour
        this.showIcalUrlModal = true
      })
    },
    importIcal({ url, check_in_hour, check_out_hour }) {
      api.import_ics(this.importPropertyId, url, check_in_hour, check_out_hour).then(response => {
        this.starts = Object.getOwnPropertyNames(response.data.starts)
        this.uids = Object.values(response.data.starts)
        this.unreserved_ranges = response.data.unreserved_ranges
        this.showIcalUrlModal = false
        this.showPickDateModalRaw = true
      }, (errors) => {
        this.showPickDateModalRaw = false
        this.$flasher.errors(errors.response.data.errors)
      })
    }
  },
  mounted() {},
  watch: {
    show(newValue) {
      if (newValue) {
        this.pickedDates = []
        this.pickedRanges = []
        for (var i = 0; i < this.fixStarts.length; i++) {
          this.pickedDates.push({
            checked: true,
            date: Date.parse(this.fixStarts[i]).toString("M/d h:mm tt")
          })
        }
/*
        for (var i = 0; i < this.flexibleRanges.length; i++) {
          this.pickedRanges.push({
            checked: true,
            date: [ new Date(this.flexibleRanges[i][0]).toString("M/d/yyyy"), new Date(this.flexibleRanges[i][1]).toString("M/d/yyyy") ]
          })
        }
*/
      }

    }
  }
}
