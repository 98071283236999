<template>
  <div>
    <base-modal classes="calendar-modal background-soft-gray" modal-size="" dom-id="pickDateModal" :show="show" :title="title" @close="$emit('close')">
      <div slot="content">
        <div v-if="loading" class="row">
          <div class="col-12">
            <clip-loader :loading="loading"></clip-loader>
          </div>
        </div>
        <template v-if="!loading">
          <div class="row justify-content-center">
            <div class="col-12 table-responsive">
              <table class="table table-striped">
                <tbody>
                  <tr v-for="date in pickedDates">
                    <td v-if="date.no_hours" class="text-center">
                      <h6 class="text-danger">{{date.date}} CAN NOT BE BOOKED</h6>
                    </td>
                    <td v-else class="text-center">
                      <div class="row">
                        <div class="col-sm-5">
                          <input class="form-check-input" type="checkbox" v-model="date.checked" :id="'date_'+date.date">
                          <label class="form-check-label" :for="'date_'+date.date">
                            {{date.date}}
                          </label>
                        </div>
                        <div class="col-sm-7">
                          <plan-dropdown v-model="date.plan" :hide-label="true" css-class="" />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="false" class="row justify-content-center">
            <h6>Flexible periods</h6>
            <div class="col-12 table-responsive">
              <table class="table table-striped">
                <tbody>
                  <tr v-for="date in pickedRanges">
                    <td class="text-center">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="date.checked" :id="'date_'+date.date">
                        <label class="form-check-label" :for="'date_'+date.date[0]">
                          Between {{date.date[0]}} and {{date.date[1]}}
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="false" class="col-12 text-center">
              <p>{{flexibleDescription}}
                <br><a href="#" @click="$emit('toggle-flexible', true)" class="btn btn-link stretched-link">Change</a></p>
            </div>
          </div>
        </template>
      </div>
      <div v-if="!loading" slot="footer">
        <button type="button" class="btn btn-warning" @click="requestDates">Request Bookings</button>
      </div>
    </base-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);
import Spinner from '@/shared/spinner.vue'
import Flasher from '@/flasher'
Vue.use(Flasher)
import BaseModal from '@/modals/base_modal.vue'
import PlanDropdown from '@/shared/fields/plan_dropdown.vue'
import { timeToAppFormat } from '@/utils'

export default {
  props: {
    fixStarts: {
      type: Array,
      required: true
    },
    flexibleRanges: {
      type: Array,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    flexibleDescription: {
      type: String,
      required: true
    }
  },
  components: {
    BaseModal,
    Spinner,
    PlanDropdown
  },
  data() {
    return {
      loading: false,
      pickedDates: [],
      pickedRanges: []
    }
  },
  methods: {
    requestDates() {
      var starts = []
      for (var i = 0; i < this.pickedDates.length; i++) {
        if (this.pickedDates[i].checked) {
          const date = this.pickedDates[i].date_value
          const plan = this.pickedDates[i].plan
          starts.push({ start: date, plan: plan, i: i })
        }
      }
      this.$emit('request', starts)
    },
  },
  mounted() {},
  watch: {
    show(newValue) {
      if (newValue) {
        this.pickedDates = []
        this.pickedRanges = []
        for (var i = 0; i < this.fixStarts.length; i++) {
          const date = Date.parse(this.fixStarts[i])
          const time_value = timeToAppFormat(date)
          const no_hours = date.getHours() == 0
          var date_str = date.toString("M/d h:mm tt")
          if (no_hours) {
            date_str = date.toString("M/d")
          }
          this.pickedDates.push({
            checked: !no_hours,
            no_hours: no_hours,
            plan: 'basic',
            date: date_str,
            date_value: time_value
          })
        }
      }

    }
  }
}
</script>
