<template>
  <base-modal
    dom-id="askIcalUrlModal"
    :show="show"
    classes="modal-content-quantity"
    bodyClass="modal-body"
    title="ICS Import"
    @close="close">

    <div slot="content">
      <div class="row">
        <div class="col">
          <label for="url">Paste the public iCal URL here</label>
          <input id="url" type='text' class="form-control mb-3" v-model="url" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <hours-dropdown v-model="check_in_hour" label="Check In Hour" cssClass="" />
        </div>
        <div class="col-sm-6">
          <hours-dropdown v-model="check_out_hour" label="Check Out Hour" cssClass="" />
        </div>
        <div class="col-sm-12">
          <small>These values are going to be saved in the property</small>
        </div>
      </div>
    </div>

    <div slot="footer">
      <button @click="doImport" class="btn btn-green">Import</button>
    </div>
  </base-modal>
</template>
<script>
import BaseModal from './base_modal.vue'
import HoursDropdown from '@/shared/fields/hours_dropdown.vue'
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    propertyIcalUrl: {
      type: String
    },
    checkInHour: {
      type: Number
    },
    checkOutHour: {
      type: Number
    }
  },
  components: { BaseModal, HoursDropdown },
  data() {
    return {
      url: this.propertyIcalUrl,
      check_in_hour: this.checkInHour,
      check_out_hour: this.checkOutHour
    }
  },
  computed: {
  },
  methods: {
    close() {
      this.$emit('close')
    },
    doImport() {
      this.$emit('import', { url: this.url, check_in_hour: this.check_in_hour, check_out_hour: this.check_out_hour })
    }
  },
  watch: {
    checkInHour(newValue) {
      this.check_in_hour = newValue
    },
    checkOutHour(newValue) {
      this.check_out_hour = newValue
    },
    propertyIcalUrl(newValue) {
      this.url = newValue
    }
  }
}
</script>

